import React, { Component } from "react";
import Title from "./Title";
import Bio from "./Bio";
import Pubs from "./Pubs";
import Footer from "./Footer";
import consts from "../consts.js";
import "./style.scss";

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "",
    };
  }

  updateCurrentPage = (selected_page) => {
    this.setState({
      current_page: selected_page,
    });
  };

  componentDidMount() {
    this.setState({
      current_page: consts.pages.about,
    });
  }

  render() {
    return (
      <div className="section-container">
        <div className="section-item">
          <Title
            updateCurrentPage={this.updateCurrentPage}
            current_page={this.state.current_page}
          />
        </div>
        {this.state.current_page === consts.pages.about && (
          <div className="section-item">
            <Bio />
          </div>
        )}
        {this.state.current_page === consts.pages.publications && (
          <div className="section-item">
            <Pubs />
          </div>
        )}
        {/* <div className="section-item">
          <Experience exp_type="current" />
        </div>
        <div className="section-item">
          <Experience exp_type="previous" />
        </div>
        <div className="section-item">
          <Contact />
        </div> */}
        <div className="section-item">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Profile;
