import React, { Component } from "react";
import marked from "marked";
import consts from "../../consts.js";
import "./style.scss";

export class Bio extends Component {
  constructor(props) {
    super(props);
    this.state = { content: "" };
  }
  componentDidMount() {
    fetch(consts.paths.bio)
      .then((response) => {
        return response.text();
      })
      .then((md) => {
        this.setState({
          content: marked(md),
        });
      })
      .catch((error) => {
        console.log("load error", consts.paths.bio, error);
      });
  }
  render() {
    return (
      <div
        className="subsection-container"
        dangerouslySetInnerHTML={{ __html: this.state.content }}
      ></div>
    );
  }
}

export default Bio;
