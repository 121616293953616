import React, { Component } from "react";
import Profile from "./Profile";
import "./style.scss";

export class App extends Component {
  render() {
    return (
      <div className="main-container mx-auto">
        <Profile />
      </div>
    );
  }
}

export default App;
