import React, { Component } from "react";
import consts from "../../consts.js";
import "./style.scss";

export class Pubs extends Component {
  constructor(props) {
    super(props);
    this.state = { pubs: [] };
  }
  componentDidMount() {
    fetch(consts.paths.pubs)
      .then((response) => {
        return response.text();
      })
      .then((md) => {
        this.setState({
          pubs: JSON.parse(md),
        });
      })
      .catch((error) => {
        console.log("load error", consts.paths.pubs, error);
      });
  }
  render() {
    return (
      <div className="subsection-container" id="publications">
        {/* <div className="subsection-title">Research Publications</div> */}
        <div className="pubs-list">
          {this.state.pubs.map((pub, i) => {
            return (
              <div className="pubs-item" key={i}>
                <div className="pubs-item-index">{i + 1})</div>

                <div className="pubs-item-content">
                  <div className="pubs-title">
                    <a onClick={() => window.open(pub.url)} href="#">
                      {pub.title}
                    </a>
                  </div>

                  <div className="pubs-conference">
                    <span>{pub.conference}, </span>
                    <span className="pubs-year">{pub.year}</span>
                  </div>

                  {pub.venue.length > 0 && (
                    <div className="pubs-venue">{pub.venue}</div>
                  )}

                  <div className="pubs-authors">
                    {pub.authors.split(",").map((author, i) => {
                      let author_element = author.includes("*") ? (
                        <React.Fragment>
                          {author.slice(0, -1)}
                          <sup>*</sup>
                        </React.Fragment>
                      ) : (
                        author
                      );

                      if (author.includes(consts.zain))
                        return (
                          <span className="author-highlight" key={i}>
                            {author_element}
                          </span>
                        );

                      return <span key={i}>{author_element}</span>;
                    })}
                  </div>

                  {pub.award && pub.award.length > 0 && (
                    <div className="pubs-award">
                      &#9733; {pub.award} &#9733;
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="subsection-footnote">* indicates co-first authors</div>
      </div>
    );
  }
}

export default Pubs;
