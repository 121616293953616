import React, { Component } from "react";
import "./style.scss";

export class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        {/* <div className="footer-content">
          © {new Date().getFullYear()} | Zain Tariq
        </div> */}
      </div>
    );
  }
}

export default Footer;
