const consts = {
  zain: "Zain Tariq",
  dp: {
    src: "/assets/img/dp.png",
    alt: "Zain",
  },
  urls: {
    linkedin: "https://www.linkedin.com/in/tariqzain/",
  },
  paths: {
    pubs: "/assets/pubs/pubs.json",
    bio: "/assets/bio/bio.md",
    contact: "/assets/contact/contact.md",
    current_experience: "/assets/experience/current.md",
    previous_experience: "/assets/experience/previous.md",
  },
  pages: {
    about: "about",
    publications: "publications",
    linkedin: "linked in",
  },
};

export default consts;
