import React, { Component } from "react";
import consts from "../../consts.js";
import "./style.scss";

export class Title extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  render() {
    return (
      <div
        className="title-container"
        onMouseOver={() => {
          this.setState({ hovered: true });
        }}
        onMouseLeave={() => {
          this.setState({ hovered: false });
        }}
      >
        {/* <div
          className={`title-img ${this.state.hovered ? "hovered" : ""}`}
          style={{
            backgroundImage: "url(/assets/img/dp.png)",
          }}
        ></div> */}
        <div className="title-img-container">
          <img
            className={`title-img ${this.state.hovered ? "hovered" : ""}`}
            src={consts.dp.src}
            alt={consts.dp.alt}
          />
        </div>
        <div className="title-content-container">
          <div className="title-name">{consts.zain}</div>
          <div className="title-links">
            <button
              className={`${
                this.props.current_page === consts.pages.about ? "selected" : ""
              }`}
              onClick={() => this.props.updateCurrentPage(consts.pages.about)}
            >
              {consts.pages.about}
            </button>
            <button
              className={`${
                this.props.current_page === consts.pages.publications
                  ? "selected"
                  : ""
              }`}
              onClick={() =>
                this.props.updateCurrentPage(consts.pages.publications)
              }
            >
              {consts.pages.publications}
            </button>
            <button onClick={() => window.open(consts.urls.linkedin)}>
              {consts.pages.linkedin}
            </button>
            {/* <a href="#current">current research</a>
            <a href="#previous">previous research</a> */}
            {/* <a href="#contact">contact</a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Title;
